import { FixtureApi } from '@unspoiled/api-football-client';
import { supported_league_ids } from "@unspoiled/api";
import {
    Fixture,
    FixtureGroup,
    FixtureSet,
    GroupMetadata,
    standard_date,
    standard_date_string
} from "@unspoiled/fixture";
import { UnspoiledApi } from "@unspoiled/unspoiled-api-client";
import { dateDelta } from "../util";

let DOMAIN : string;
let FIXTURE_KEY : string;
let ADMIN : boolean;
let SEASON = "2022";
if (process.env.REACT_APP_ENV === "production") {
    DOMAIN = 'fu8u4rqbl2.execute-api.us-west-1.amazonaws.com';
    FIXTURE_KEY = 'M1SzN4tSwumshpcpwN01lwCQBlE0p1IwwSNjsn49tkFX6JaGCk'
    ADMIN = false;
} else if (process.env.REACT_APP_ENV === "admin"){
    DOMAIN = 'fu8u4rqbl2.execute-api.us-west-1.amazonaws.com';
    FIXTURE_KEY = 'M1SzN4tSwumshpcpwN01lwCQBlE0p1IwwSNjsn49tkFX6JaGCk'
    ADMIN = true;
} else {
    DOMAIN = 'qs9on1vctb.execute-api.us-west-1.amazonaws.com';
    FIXTURE_KEY = 'de0dafc4famsh6d18927d102dec6p15e4dejsn80be8fb03859';
    ADMIN = false;
}

export class _Repository {
    fixture_api: FixtureApi;
    unspoiled_api: UnspoiledApi;
    readonly supported: Array<number>;

    fixtureStore: FixtureSet;

    private constructor() {
        this.fixture_api = new FixtureApi({key: FIXTURE_KEY});
        this.unspoiled_api = new UnspoiledApi(DOMAIN);
        this.supported = supported_league_ids;
        this.fixtureStore = new FixtureSet();
    }

    /** Singleton **/
    private static instance: _Repository;
    public static get_instance(): _Repository {
        if (!_Repository.instance) {
            _Repository.instance = new _Repository();
        }
        return _Repository.instance;
    }

    is_admin() {
        return ADMIN;
    }

    get_group(date: string, league: number) : FixtureGroup | undefined {
        return this.fixtureStore.get(date, league);
    }

    async load_fixtures(date: string) : Promise<void> {
        const dd = dateDelta(date);

        let new_fixes : FixtureSet;
        if (dd === 0) new_fixes = await this._get_fixtures_today(date);
        else if (dd > 0) new_fixes = await this._get_fixtures_future(date);
        else new_fixes = await this._get_fixtures_past(date);

        this.fixtureStore = new_fixes; // Todo: Merge cache
    }

    async _get_fixtures_today(date: string) : Promise<FixtureSet> {
        const saved_fixes = await this.unspoiled_api.fetchFixtures(date, this.supported);
        const live_fixes = await this.fixture_api.getByLeagues(this.supported, standard_date(date), SEASON);
        saved_fixes.merge(live_fixes);
        return saved_fixes;
    }

    async _get_fixtures_future(date: string) : Promise<FixtureSet> {
        return await this.fixture_api.getByLeagues(this.supported, standard_date(date), SEASON);
    }

    async _get_fixtures_past(date: string) : Promise<FixtureSet> {
        return await this.unspoiled_api.fetchFixtures(date, this.supported);
    }

    async modify_featured(fixture: Fixture, d_featured: number) : Promise<number | undefined> {
        return await this.unspoiled_api.modify_featured(fixture, d_featured);
    }
}

export const Repository = _Repository.get_instance();