import './styles/App.css';
import './styles/App.mobile.css';
import { useState, createContext } from 'react';
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import * as components from './components/components';
import * as screens from './screens/screens';
import {standard_date_string} from "@unspoiled/fixture";
import {supported_league_ids} from "@unspoiled/api";

export enum ScreenSize {
    mobile,
    desktop
}

function getScreenSize() : ScreenSize {
    if (window.innerWidth <= 900)
        return ScreenSize.mobile;
    else
        return ScreenSize.desktop;
}

interface AppContextState {
    screen_size: ScreenSize;
}

export const AppContext =  createContext<AppContextState>({ screen_size: ScreenSize.desktop});

function MainApp() {
    const [screen_size, setScreenSize] = useState(getScreenSize());

    window.addEventListener("resize", () => setScreenSize(getScreenSize()));

    return (
        <div className="App">
            <AppContext.Provider value={{screen_size: screen_size}}>
                <header className="App-header">
                    <components.Header/>
                </header>
                <div className="App-body">
                    <Outlet/>
                </div>
            </AppContext.Provider>
        </div>
    );
}

export function AppRoutes() {
    const default_day = standard_date_string();
    const default_league = supported_league_ids[0];
    return (
        <Routes>
            <Route path="/" element={ MainApp() }>
                <Route path="highlights" element={ <screens.HighlightScreen/> }>
                    <Route path=":date" element={ <components.DateGroup/> }>
                        <Route path=":league" element={ <components.LeagueGroup/> }/>
                        <Route index element={ <Navigate to={`${default_league}`} replace/> } />
                    </Route>
                    <Route index element={ <Navigate to={`${default_day}/${default_league}`} replace/> } />
                </Route>
                <Route index element={ <Navigate to={`highlights/${default_day}/${default_league}`} replace/> } />
            </Route>
            <Route index element={ <Navigate to={`/highlights/${default_day}/${default_league}`} replace/> } />
        </Routes>
    );
}