"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fixture = void 0;
const utils_1 = require("./utils");
// A single fixture
class Fixture {
    constructor(props) {
        this.has_vid = () => this.props.vid !== undefined && this.props.vid !== "";
        this.update_vid = (vid) => this.props.vid = vid;
        this.props = props;
    }
    to_db() {
        return {
            id: this.props.id,
            league: this.props.league,
            date: this.props.date.toISOString(),
            home: this.props.home,
            away: this.props.away,
            gametime: this.props.gametime,
            score: this.props.score,
            status: this.props.status,
            vid: this.props.vid,
            featured: this.props.featured
        };
    }
    static from_db(dbsf) {
        return new Fixture(Object.assign(Object.assign({ 
            // Defaults
            featured: 0 }, dbsf), { 
            // Overrides
            date: (0, utils_1.standard_date)(dbsf.date) }));
    }
    update(fixture) {
        this.props.gametime = fixture.props.gametime || this.props.gametime;
        this.props.score = fixture.props.score || this.props.score;
        this.props.status = fixture.props.status || this.props.status;
        this.props.vid = fixture.props.vid || this.props.vid;
        this.props.featured = fixture.props.featured || this.props.featured;
    }
}
exports.Fixture = Fixture;
