"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixtureGroup = void 0;
// A single array of fixtures for a (date, league) pair
const fixture_1 = require("./fixture");
class FixtureGroup {
    constructor(props) {
        this.props = props;
    }
    static from_db(record) {
        return new FixtureGroup({
            date: record.date,
            league: record.league,
            fixtures: record.fixtures.map((f) => fixture_1.Fixture.from_db(f)),
            meta: record.meta
        });
    }
    to_db() {
        return {
            date: this.props.date,
            league: this.props.league,
            fixtures: this.props.fixtures.map((f) => f.to_db()),
            meta: this.props.meta
        };
    }
    map(f) {
        return this.props.fixtures.map(f);
    }
    update_fixture(fixture) {
        for (let fix of this.props.fixtures) {
            if (fix.props.id !== fixture.props.id)
                continue;
            fix.update(fixture);
        }
    }
    update_meta(update_props) {
        this.props.meta = Object.assign(Object.assign({}, this.props.meta), update_props);
    }
}
exports.FixtureGroup = FixtureGroup;
