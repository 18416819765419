"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MockApiFootballClient = exports.MockClient = void 0;
class MockClient {
    constructor(path) {
        this.path = path;
    }
    request(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return {};
        });
    }
}
exports.MockClient = MockClient;
let ob = {
    method: 'GET',
    url: `/fixtures`,
    params: {
        "date": "2022-10-11",
        "league": 2,
        "season": "2022",
        "timezone": "America/Los_Angeles"
    }
};
class MockApiFootballClient extends MockClient {
    request(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return {};
        });
    }
    hashOptions(options) {
        const url = options.url.replace("/", "\\");
        return `${options.method}_${url}_${this.hashParams(options.params)}`;
    }
    hashParams(params) {
        return `${params.date}_${params.league}_${params.season}_${params.timezone}`;
    }
}
exports.MockApiFootballClient = MockApiFootballClient;
