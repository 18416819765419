import { NameReplacementSet } from "../models/models";

export function standardize_team_name(team: string) {
    if (api_names.hasOwnProperty(team))
        return api_names[team];
    return team;
}

export function clean_w(team: string) {
    const split = team.split(" ");
    if (split[split.length - 1] === "W")
        return split.slice(0, split.length - 1).join(" ");
    return team;
}

const api_names = {
    // UCL
    "PSG": "Paris Saint Germain",
    "Man. City": "Manchester City",
    "Salzburg": "Red Bull Salzburg",
    "Inter Milan" : "Inter",
    "Tottenham Hotspur" : "Tottenham",
    "Club Brugge" : "Club Brugge KV",
    "Atl\u{00e9}tico de Madrid": "Atletico Madrid",
    "Atl\u{00e9}tico Madrid": "Atletico Madrid",
    "Viktoria Plzen" : "Plzen",
    "Porto" : "FC Porto",
    "Bayern" : "Bayern Munich",
    "Copenhagen" : "FC Copenhagen",
    // PL
    "Newcastle United" : "Newcastle",
    "Leicester City" : "Leicester",
    "Leeds United" : "Leeds",
    "West Ham United" : "West Ham",
    // NWSL
    "Racing Louisville FC": "Racing Louisville",
    "San Diego Wave FC" : "San Diego Wave",
    "Kansas City Current": "Kansas City",
    "Portland Thorns FC": "Portland Thorns",
    // Women's World Cup
    "United States": "USA",
    // MLS
    "Austin FC" : "Austin",
    "Vancouver Whitecaps FC" : "Vancouver Whitecaps",
    "Chicago Fire FC" : "Chicago Fire",
    "D.C. United": "DC United",
    "Inter Miami CF": "Inter Miami",
    "Charlotte FC" : "Charlotte",
    "Houston Dynamo FC" : "Houston Dynamo",
    "Seattle Sounders FC" : "Seattle Sounders",
    // Ligue 1
    "Brest" : "Stade Brestois 29",
    "Troyes" : "Estac Troyes",
    // Serie A
    "Roma" : "AS Roma",
    // Other
    "Hellas Verona" : "Verona"
} as NameReplacementSet;

export const short_names = {
    "Paris Saint Germain": "PSG",
    "Manchester United": "MUN",
    "Aston Villa": "AVL",
    "Manchester City": "MCI",
    "West Ham": "WHU",
    "Red Bull Salzburg": "RBS",
    "Club Brugge KV": "CLB",
    "FC Porto": "POR",
    "Bayern Munich": "MUN",
    "FC Copenhagen": "COP",
} as NameReplacementSet;