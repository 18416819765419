import './league-group.css';
import { Repository } from "../../repository/repository";
import React, { MouseEvent, useEffect, useState } from "react";
import { CircularProgress, Divider, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { LEAGUE_ICON_DOMAIN } from "../../constants";
import { get_supported_league } from "@unspoiled/api";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { FixtureGroup, GroupMetadata } from "@unspoiled/fixture";
import { ScoreCardGroup } from "../scorecard-group/scorecard-group";
import { DateGroupOutletContext } from "../date-group/date-group";

export function LeagueGroup() {
    let { league: urlLeague_s } = useParams<{ league: string }>();
    let urlLeague = (urlLeague_s) ? parseInt(urlLeague_s) : 0;

    const [group, setGroup] = useState(undefined as FixtureGroup | undefined);

    let { loaded, date } = useOutletContext<DateGroupOutletContext>()

    useEffect(() => {
        if (!loaded) return;
        const group = Repository.get_group(date, urlLeague);
        setGroup(group);
    }, [date, urlLeague, loaded]);

    return (
        <div>
            <LeagueSelector league={urlLeague} loaded={loaded} />
            <Divider variant="middle" className="league-group-divider"/>
            {
                (loaded) ?
                    <ScoreCardGroup group={group}/> :
                    <CircularProgress />
            }
        </div>
    );
}

interface LeagueSelectorProps {
    league: number;
    loaded: boolean;
}

function LeagueSelector(props: LeagueSelectorProps) {
    const navigate = useNavigate();

    const handleLeague = async (
        event: MouseEvent<HTMLElement>,
        newLeague: number | null,
    ) => {
        if (!newLeague || !props.loaded) return;
        navigate(`../${newLeague}`);
    }

    const buildToggleButton = (league: number) => {
        const item = get_supported_league(league);
        return (
            <ToggleButton value={item.id} aria-label={item.name} key={item.name} className="league-selector-button">
                <img src={LEAGUE_ICON_DOMAIN + item.id.toString() + ".png"} className="league-selector-icon" alt={item.name}/>
            </ToggleButton>
        );
    };

    return (
        <div className="league-selector-container">
            <ToggleButtonGroup
                value={props.league}
                exclusive
                onChange={handleLeague}
                aria-label="league select"
                className="league-selector-toggle-group"
            >
                { Repository.supported.map(buildToggleButton) }
            </ToggleButtonGroup>
        </div>
    );
}