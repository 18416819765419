import './global_types';
import { Color } from "./global_types";
import {standard_date, standard_date_string} from "@unspoiled/fixture";

export function colorToString(c: Color) { return `rgb(${c.r}, ${c.g}, ${c.b})`; }
export const COLORS = {
    black: {r: 0, g: 0, b: 0},
    blue: {r: 38, g: 98, b: 172},
    red:{r: 177, g: 29, b: 37}
};

export function parseEmbedUrl(embed : string) {
    embed.replace('\\', '');
    const start = embed.indexOf('https:');
    const end = embed.indexOf('\' frameborder');
    return embed.slice(start, end);
}

export function parseHighlightName(name : string) {
    let team_name = "";
    for (let piece of name.split(" ")) {
        if (piece !== '') {
            team_name += piece;
            team_name += '-';
        }
    }
    return team_name.slice(0, team_name.length - 1);
}

export function flatten(l: Array<Array<any>>) : Array<any> {
    let final = Array<any>();
    let f = (i: any) => final.push(i);
    l.map((arr) => arr.map(f))
    return final;
}

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// 0 = today, 1 = future, -1 = past
export function dateDelta(date: string | Date) : number {
    const now = standard_date();
    const s_date : Date = (date instanceof Date) ? date : standard_date(date);
    if (standard_date_string(s_date) === standard_date_string(now)) return 0;
    else if (now < s_date) return 1;
    else return -1;
}