import './scorecard.css';
import './scorecard.mobile.css';
import {Component, MouseEvent, useEffect, useState} from "react";
import { AppContext, ScreenSize } from "../../App";
import { HighlightVideo } from "../highlight-video/highlight-video";
import { TEAM_ICON_DOMAIN } from "../../constants";
import { Fixture, Team } from "@unspoiled/fixture";
import { dateDelta, sleep } from "../../util";
import { Repository } from "../../repository/repository";

import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import StarRateIcon from '@mui/icons-material/StarRate';

interface ScoreCardProps {
    fixture: Fixture;
}

interface ScoreCardState {
    id: number;
    fixture: Fixture;
    highlightOpen?: boolean;
    highlightRendered: boolean;
}

export class ScoreCard extends Component<ScoreCardProps, ScoreCardState> {

    constructor(props: ScoreCardProps) {
        super(props);

        this.state = {
            id: props.fixture.props.id,
            fixture: props.fixture,
            highlightRendered: false
        };
    }

    shortenName = (t: Team) => {
        if (t.short)
            return t.short;
        return t.name.toUpperCase().substring(0, 3);
    }
    crestUrl = (t: number) => TEAM_ICON_DOMAIN + t.toString() + ".png";

    toggleButton = () => {
        if (!this.state.fixture.props.vid) return;

        // Wait to un-render highlight-video until animation finishes
        if (this.state.highlightOpen)
            sleep(1500).then(() => this.setState({ highlightRendered: false }));
        // Wait to render highlight-video until animation near finishing
        else
            this.setState({ highlightRendered: true });

        this.setState({ highlightOpen: !this.state.highlightOpen });
    }

    render() {
        let text_content = (
                <AppContext.Consumer>
                    {({ screen_size }) => {
                        let h_team_name = (screen_size === ScreenSize.mobile) ?
                            this.shortenName(this.state.fixture.props.home) :
                            this.state.fixture.props.home.name;
                        let a_team_name = (screen_size === ScreenSize.mobile) ?
                            this.shortenName(this.state.fixture.props.away) :
                            this.state.fixture.props.away.name;
                        return (
                            <div className="scorecard-text-content">
                                <p className="scorecard-text">{h_team_name}</p>
                                <FixtureInfoBox fixture={this.state.fixture}
                                                screenSize={screen_size}
                                                highlightOpen={this.state.highlightOpen}/>
                                <p className="scorecard-text">{a_team_name}</p>
                            </div>
                        );
                    }}
                </AppContext.Consumer>
        );

        const card_type = (this.props.fixture.props.featured) ? "featured" : "regular";

        return (
            <div className={"scorecard scorecard-" + card_type}>
                <div className="scorecard-main" onClick={this.toggleButton}>
                    <img src={this.crestUrl(this.state.fixture.props.home.id)} className="scorecard-seal-h scorecard-seal" alt="home crest"/>
                    { text_content }
                    <img src={this.crestUrl(this.state.fixture.props.away.id)} className="scorecard-seal-a scorecard-seal" alt="away crest"/>
                </div>
                <HighlightVideo open={this.state.highlightOpen}
                                fixture={this.state.fixture}
                                rendered={this.state.highlightRendered}/>
            </div>
        );

    }
}

interface FixtureInfoProps {
    fixture: Fixture;
    screenSize: ScreenSize;
    highlightOpen?: boolean;
}

function HighlightIcon(props: FixtureInfoProps) {
    const size = (props.screenSize === ScreenSize.mobile) ? "medium" : "large";
    const card_type = (props.fixture.props.featured) ? "featured" : "regular";
    return (
        <div className={"scorecard-highlight-icon-container-" + card_type}>
            <MovieCreationIcon fontSize={size} className="scorecard-highlight-icon"/>
        </div>
    );
}

function FixtureInfoBox(props: FixtureInfoProps) {
    if (props.fixture.has_vid()) {
        if (Repository.is_admin())
            return FeaturedButton(props);
        return HighlightIcon(props);
    }

    let info_str = "";
    if (dateDelta(props.fixture.props.date) < 0)
        info_str = "FT";
    else if (props.fixture.props.status === "FT" || props.fixture.props.status === "HT")
        info_str = props.fixture.props.status;
    else if (props.fixture.props.gametime)
        info_str = props.fixture.props.gametime.toString() + "'";
    else
        info_str = timeString(props.fixture.props.date);

    return (
        <div className="scorecard-fixture-info-box">
            <p className="scorecard-fixture-info-text">{info_str}</p>
        </div>
    );
}

function timeString(date: Date) : string {
    const hrs24 = date.getHours();
    const ampm = (hrs24 < 12) ? "AM" : "PM";
    const hrs = (hrs24 % 12 === 0) ? 12 : hrs24 % 12;
    const mins = (date.getMinutes()) ? date.getMinutes().toString() : "00";
    //const tz = new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];
    return hrs.toString() + ":" + mins + " " + ampm;
}

function FeaturedButton(props: FixtureInfoProps) {
    let [featured, setFeatured] = useState(props.fixture.props.featured > 0);
    let [button_pressed_once, setButtonPressed] = useState(false);

    useEffect(() => {
        if (!button_pressed_once) return;
        // Update featured count in db
        Repository.modify_featured(props.fixture, (featured) ? 1 : -1)
            .then((res) => console.log("R", res));
    }, [featured, button_pressed_once]);

    const size = (props.screenSize === ScreenSize.mobile) ? "medium" : "large";
    const card_type = (props.fixture.props.featured) ? "featured" : "regular";

    const onClick = (event: MouseEvent<SVGElement>) => {
        setButtonPressed(true);
        setFeatured(!featured);
        event.stopPropagation();
    }

    return (
        <div className={"scorecard-highlight-icon-container-" + card_type}>
            {
                (featured) ?
                    <StarRateIcon fontSize={size} className="scorecard-highlight-icon" onClick={onClick}/> :
                    <StarBorderPurple500Icon fontSize={size} className="scorecard-highlight-icon" onClick={onClick}/>
            }
        </div>
    );
}