import {DB_Unmatched_Highlight, Gender, SupportedLeague} from "../models/models";
import {standard_date_string} from "@unspoiled/fixture";

export function get_supported_league(league: number) {
    return supported_leagues[league];
}

const supported_leagues = {
    2: {
        id: 2,
        name: "Champions League",
        season: "2023",
        gender: Gender.MEN,
        playlist: {
            channelId: "UCET00YnetHT7tOpu12v8jxg",
            query_base: "Extended Highlights",
            highlight_filter: i =>
                i.snippet.title.includes("Extended Highlights"),
            highlight_converter: (v) => {
                // Example -- Borussia Dortmund vs. Copenhagen: Extended Highlights | UCL Group Stage MD 1 | CBS Sports Golazo
                const [teams, ] = v.snippet.title.split(': ');
                const [home, away] = teams.split(' vs. ');
                return {
                    vid: v.id.videoId,
                    home: home,
                    away: away,
                    date: standard_date_string(v.snippet.publishTime),
                    league: 2
                } as DB_Unmatched_Highlight;
            }
        }
    },
    39: {
        id: 39,
        name: "Premier League",
        season: "2023",
        gender: Gender.MEN,
        playlist: {
            channelId: "UCqZQlzSHbVJrwrn5XvzrzcA", // NBCSports
            query_base: "PREMIER LEAGUE HIGHLIGHTS",
            highlight_filter: i => i.snippet.title.includes("PREMIER LEAGUE HIGHLIGHTS"),
            highlight_converter: (v) => {
                // Example -- "Leeds United v. Chelsea | PREMIER LEAGUE HIGHLIGHTS | 8/21/2022 | NBC Sports"
                const [teams, , date, ] = v.snippet.title.split(' | ');
                const [home, away] = teams.split(' v. ');
                return {
                    vid: v.id.videoId,
                    home: home,
                    away: away,
                    date: standard_date_string(date),
                    league: 39
                } as DB_Unmatched_Highlight;
            }
        }
    },
    8: {
        id: 8,
        name: "World Cup",
        season: "2023",
        gender: Gender.WOMEN,
        playlist: {
            channelId: "UCooTLkxcpnTNx6vfOovfBFA",
            query_base: "2023 FIFA World Cup Women highlights",
            highlight_filter: i => i.snippet.title.includes("Highlights")
                                    && i.snippet.title.includes("World Cup")
                                    && i.snippet.title.includes("2023 FIFA Women"),
            highlight_converter: (v) => {
                // Example -- United States vs. Vietnam Highlights | 2023 FIFA Women&#39;s World Cup
                let [teams, ] = v.snippet.title.split('Highlights |');
                if (!teams.includes(' vs. ')) teams = teams.replace('vs', 'vs.');
                const [home, away] = teams.split(' vs. ');
                return {
                    vid: v.id.videoId,
                    home: home,
                    away: away,
                    date: standard_date_string(v.snippet.publishTime),
                    league: 8
                } as DB_Unmatched_Highlight;
            }
        }
    },
    254: {
        id: 254,
        name: "NWSL",
        season: "2023",
        gender: Gender.WOMEN,
        playlist: {
            channelId: "UCX_tjI6Q_4JD1E3234CwemA",
            query_base: "Extended Highlights NWSL",
            highlight_filter: i => i.snippet.title.includes("Extended Highlights") && i.snippet.title.includes("NWSL"),
            highlight_converter: (v) => {
                // Example -- North Carolina Courage vs. Portland Thorns: Extended Highlights | NWSL | CBS Sports Attacking Third
                let [teams, ] = v.snippet.title.split(':');
                if (!teams.includes(' vs. ')) teams = teams.replace('vs', 'vs.');
                const [home, away] = teams.split(' vs. ');
                return {
                    vid: v.id.videoId,
                    home: home,
                    away: away,
                    date: standard_date_string(v.snippet.publishTime),
                    league: 254
                } as DB_Unmatched_Highlight;
            }
        }
    },
    641: {
        id: 641,
        name: "NWSL Challenge Cup",
        season: "2023",
        gender: Gender.WOMEN,
        playlist: {
            channelId: "UCX_tjI6Q_4JD1E3234CwemA",
            query_base: "Extended Highlights NWSL",
            highlight_filter: (i) => {
                return i.snippet.title.includes("Extended Highlights")
                    && i.snippet.title.includes("NWSL")
            },
            highlight_converter: (v) => {
                // Example -- North Carolina Courage vs. Portland Thorns: Extended Highlights | NWSL | CBS Sports Attacking Third
                let [teams, ] = v.snippet.title.split(':');
                if (!teams.includes(' vs. ')) teams = teams.replace('vs', 'vs.');
                const [home, away] = teams.split(' vs. ');
                return {
                    vid: v.id.videoId,
                    home: home,
                    away: away,
                    date: standard_date_string(v.snippet.publishTime),
                    league: 641
                } as DB_Unmatched_Highlight;
            }
        }
    },
    135: {
        id: 135,
        name: "Serie A",
        season: "2023",
        gender: Gender.MEN,
        playlist: {
            channelId: "UCET00YnetHT7tOpu12v8jxg",
            query_base: "Extended Highlights Serie A",
            highlight_filter: i =>
                i.snippet.title.includes("Extended Highlights") &&
                i.snippet.title.includes("Serie A"),
            highlight_converter: (v) => {
                // Example -- Sampdoria vs. Juventus: Extended Highlights | Serie A | CBS Sports Golazo
                const [teams, ] = v.snippet.title.split(': ');
                const [home, away] = teams.split(' vs. ');
                return {
                    vid: v.id.videoId,
                    home: home,
                    away: away,
                    date: standard_date_string(v.snippet.publishTime),
                    league: 135
                } as DB_Unmatched_Highlight;
            }
        }
    },
    253: {
        id: 253,
        name: "MLS",
        season: "2023",
        gender: Gender.MEN,
        playlist: {
            channelId: "UCSZbXT5TLLW_i-5W8FZpFsg",
            query_base: "Highlights",
            highlight_filter: i => i.snippet.title.includes("HIGHLIGHTS:"),
            highlight_converter: (v) => {
                // Example -- HIGHLIGHTS: Sporting Kansas City vs. Portland Timbers | August 21, 2022
                const [, teamsAndDate ] = v.snippet.title.split(': ');
                const [teams, date] = teamsAndDate.split(' | ');
                const [home, away] = teams.split(' vs. ');
                return {
                    vid: v.id.videoId,
                    home: home,
                    away: away,
                    date: standard_date_string(date),
                    league: 253
                };
            }
        }
    },
    61: {
        id: 61,
        name: "Ligue 1",
        season: "2023",
        gender: Gender.MEN,
        playlist: {
            channelId: "UC0YatYmg5JRYzXJPxIdRd8g",
            query_base: "Ligue 1 Highlights",
            highlight_filter: i => i.snippet.title.includes("LIGUE 1 HIGHLIGHTS"),
            highlight_converter: (v) => {
                // Example -- Lille vs PSG | LIGUE 1 HIGHLIGHTS | 08/21/2022 | beIN SPORTS USA
                const [teams, , date, ] = v.snippet.title.split(' | ');
                const [home, away] = teams.split(' vs ');
                return {
                    vid: v.id.videoId,
                    home: home,
                    away: away,
                    date: standard_date_string(date),
                    league: 61
                };
            }
        }
    }
} as Record<number, SupportedLeague>;

export const supported_league_ids = [8, 254, 641];

// {
//     id: 140,
//     name: "La Liga",
//     playlist: undefined
// },
// {
//     id: 78,
//     name: "Bundesliga",
//     playlist: undefined
// },