"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.api_date_convert_to = void 0;
// https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
function api_date_convert_to(d) {
    const offset = d.getTimezoneOffset();
    d = new Date(d.getTime() - (offset * 60 * 1000));
    return d.toISOString().split('T')[0];
}
exports.api_date_convert_to = api_date_convert_to;
