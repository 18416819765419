"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixtureSet = void 0;
const utils_1 = require("./utils");
const fixture_group_1 = require("./fixture_group");
class DateSet extends Map {
}
// A set of groups of fixture
class FixtureSet {
    constructor() {
        this.fixtures = new Map();
    }
    static from_db(records) {
        let fset = new FixtureSet();
        records.map((record) => {
            fset.setFixtureGroup(fixture_group_1.FixtureGroup.from_db(record));
        });
        return fset;
    }
    to_db() {
        let records = [];
        this.fixtures.forEach((ds, date) => {
            ds.forEach((group, league) => {
                records.push(group.to_db());
            });
        });
        return records;
    }
    _newDaySet(d) {
        let day_set = new DateSet();
        this.fixtures.set(d, day_set);
        return day_set;
    }
    set(date, league, fixtures, meta = {}) {
        let d = (0, utils_1.standard_date_string)(date);
        let group = new fixture_group_1.FixtureGroup({ date: d, league: league, fixtures: fixtures, meta: meta });
        this.setFixtureGroup(group);
    }
    setFixtureGroup(group) {
        let day_set = this.fixtures.get(group.props.date);
        // Create new date set if none exists
        if (!day_set)
            day_set = this._newDaySet(group.props.date);
        // Set new value
        day_set.set(group.props.league, group);
    }
    get(date, league) {
        let d = (0, utils_1.standard_date_string)(date);
        let day_set = this.fixtures.get(d);
        if (!day_set)
            return undefined;
        return day_set.get(league);
    }
    has(date, league) {
        let d = (0, utils_1.standard_date_string)(date);
        let date_set = this.fixtures.get(d);
        if (!date_set)
            return false;
        return date_set.get(league) !== undefined;
    }
    update_fixture(fixture) {
        const group = this.get(fixture.props.date, fixture.props.league);
        // Update fixture if group exists
        if (group) {
            group.update_fixture(fixture);
            this.setFixtureGroup(group);
        }
        // Create new group and add fixture, if not
        else
            this.set(fixture.props.date, fixture.props.league, [fixture]);
    }
    merge(new_set) {
        new_set.map_fixtures((fixture) => {
            this.update_fixture(fixture);
        });
    }
    n_dates() {
        return this.fixtures.size;
    }
    n_leagues(date) {
        let d = (0, utils_1.standard_date_string)(date);
        const date_set = this.fixtures.get(d);
        if (!date_set)
            return 0;
        return date_set.size;
    }
    map_dates(f) {
        let results = [];
        this.fixtures.forEach((date_set, date) => {
            results.push(f(date_set, date));
        });
        return results;
    }
    map_leagues(f) {
        let results = [];
        this.map_dates((date_set, date) => {
            date_set.forEach((group, league) => {
                results.push(f(group));
            });
        });
        return results;
    }
    map_fixtures(f) {
        let results = [];
        this.map_dates((date_set, date) => {
            this.map_leagues((group) => {
                group.props.fixtures.map((fixture) => {
                    results.push(f(fixture));
                });
            });
        });
        return results;
    }
}
exports.FixtureSet = FixtureSet;
