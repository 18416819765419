import { API_Video } from "./youtube_api";

export enum Gender {
    MEN,
    WOMEN
}

export interface DB_Unmatched_Highlight {
    vid: string;
    date: string;
    home: string;
    away: string;
    league: number;
}

export interface PlaylistInfo {
    channelId: string;
    query_base: string;
    highlight_filter: (i: API_Video) => boolean;
    highlight_converter: (i: API_Video) => DB_Unmatched_Highlight;
}

export interface SupportedLeague {
    id: number;
    name: string;
    playlist: PlaylistInfo;
    season: string;
    gender: Gender;
}

export interface NameReplacementSet {
    [key: string]: string;
}