import YouTube, {YouTubeProps} from "react-youtube";

import './highlight-video.css';
import './highlight-video.mobile.css';
import { AppContext, ScreenSize } from "../../App";
import { Fixture } from "@unspoiled/fixture";

interface HighlightVideoProps {
    open?: boolean;
    fixture: Fixture;
    rendered: boolean;
}

export function HighlightVideo(props: HighlightVideoProps) {
    const desktop_opts: YouTubeProps['opts'] = {
        height: '390',
        width: '640',
        playerVars: { autoplay: 1 }
    };

    const mobile_opts: YouTubeProps['opts'] = {
        height: '169',
        width: '300',
        playerVars: { autoplay: 1 }
    };

    const onVideoPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    // Choose animation to perform (null, close, open)
    let anim_str = "";
    if (props.open === true) anim_str = "youtube-player-grow-anim";
    else if (props.open === false) anim_str = "youtube-player-shrink-anim";

    return (
        <AppContext.Consumer>
            {({ screen_size }) => {
                let opts = (screen_size === ScreenSize.mobile) ? mobile_opts : desktop_opts;
                let player = (props.rendered && props.fixture.has_vid())
                    ? <YouTube videoId={props.fixture.props.vid}
                               opts={opts}
                               onReady={onVideoPlayerReady}
                               className={"youtube-iframe "}/>
                    : null;
                return (
                    <div className={"highlight-video-container " + anim_str}>
                        <div className={"youtube-player-container "}>
                            { player }
                        </div>
                    </div>
                );
            }}
        </AppContext.Consumer>
    );
}