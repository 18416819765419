import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';

import { AppRoutes } from './App';
import GlobalStyle from './styles/global';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <HashRouter>
          <GlobalStyle />
          <AppRoutes />
      </HashRouter>
  </React.StrictMode>
);
