import { IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DatePicker from "react-date-picker";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { standard_date, standard_date_string } from "@unspoiled/fixture";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import './date-group.css';
import { Repository } from "../../repository/repository";

export interface DateGroupOutletContext {
    readonly loaded: boolean;
    readonly date: string;
}

export function DateGroup() {
    let { date: urlDate_s } = useParams<{ date: string }>()
    let urlDate = (urlDate_s) ? urlDate_s : standard_date_string();

    let [loaded, setLoaded] = useState(false as boolean);

    useEffect(() => {
        const fetch_fixtures = async () => {
            setLoaded(false);
             await Repository.load_fixtures(urlDate);
            setLoaded(true);
        }

        fetch_fixtures()
            .catch(console.error);
    }, [urlDate]);

    return (
        <div>
            <DateSelector date={urlDate} loaded={loaded} />
            <Outlet context={{loaded: loaded, date: urlDate}}/>
        </div>
    );
}

interface DateSelectorProps {
    readonly date: string;
    readonly loaded: boolean;
}

function DateSelector(props: DateSelectorProps) {
    const navigate = useNavigate();

    let { league: urlLeague_s } = useParams<{ league: string }>()
    let urlLeague = (urlLeague_s) ? parseInt(urlLeague_s) : 0;

    const redirect = (d: string) : void => {
        navigate(`../${d}/${urlLeague}`);
    };

    const backDate = () => {
        let d = standard_date(props.date);
        const new_date = d.getDate() - 1;
        d.setDate(new_date);
        redirect(standard_date_string(d));
    }

    const forwardDate = () => {
        let d = standard_date(props.date);
        const new_date = d.getDate() + 1;
        d.setDate(new_date);
        redirect(standard_date_string(d));
    }

    const arbitraryDate= (d: Date) => {
        if (!props.loaded) return;
        redirect(standard_date_string(d));
    }

    return (
        <div className="date-selector-container">
            <div className="date-selector">
                <IconButton className="date-selector-button" onClick={backDate}>
                    <ArrowBackIosIcon/>
                </IconButton>
                <DatePicker onChange={arbitraryDate}
                            value={standard_date(props.date)}
                            clearIcon={null}
                            calendarIcon={null}
                            key={props.date}
                            className="date-selector-picker"
                />
                <IconButton className="date-selector-button" onClick={forwardDate}>
                    <ArrowForwardIosIcon/>
                </IconButton>
            </div>
        </div>
    );
}