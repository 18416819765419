import React, { Component, ReactElement } from "react";
import './scorecard-group.css';
import './scorecard-group.mobile.css';
import { flatten } from '../../util';

import { ScoreCard } from "../scorecard/scorecard";
import { Fixture, FixtureGroup } from "@unspoiled/fixture";

export interface FeaturedFixtures {

}

interface ScoreCardGroupProps {
    group?: FixtureGroup;
}

interface ScoreCardGroupState {
    group: FixtureGroup;
    fixture_elems: Array<ReactElement>;
    loaded: boolean;
}

export class ScoreCardGroup extends Component<ScoreCardGroupProps, ScoreCardGroupState> {
    constructor(props: ScoreCardGroupProps) {
        super(props);
        this.state = {
            group: props.group,
            fixture_elems: [],
            loaded: false
        } as ScoreCardGroupState;
    }

    componentDidUpdate(prevProps: Readonly<ScoreCardGroupProps>, prevState: Readonly<ScoreCardGroupState>, snapshot?: any) {
        if (this.props.group !== this.state.group) {
            this.updateGroup(this.props.group);
        }
    }

    componentDidMount() {
        this.updateGroup(this.state.group);
    }

    sortFixtures(group: FixtureGroup) : Array<Fixture> {
        // Sort remaining by vid, gametime, then kickoff time
        group.props.fixtures.sort((a: Fixture, b: Fixture) : number => {
            if (a.props.featured && b.props.featured)
                return b.props.featured - a.props.featured;
            if (a.props.featured) return -1;
            if (b.props.featured) return 1;

            if (a.props.vid && !b.props.vid) return -1;
            if (b.props.vid && !a.props.vid) return 1;

            // Sort by gametime
            if (a.props.gametime && b.props.gametime) {
                return a.props.gametime - b.props.gametime;
            }
            else if (a.props.gametime) return -1;
            else if (b.props.gametime) return 1;

            // Sort by kickoff time
            return a.props.date.valueOf() - b.props.date.valueOf();
        });

        return group.props.fixtures;
    }

    updateGroup(group: FixtureGroup | undefined) : FixtureGroup | undefined {
        if (!group) return;
        group.props.fixtures = this.sortFixtures(group);
        let fixture_components = flatten(group.map(
            (fix) => {
                return [
                    <ScoreCard fixture={fix} key={fix.props.id}/>,
                    <div className="scorecard-break" key={fix.props.id.toString() + "-br"}/>
                ];
            }
        ));

        this.setState({
            group: group,
            fixture_elems: fixture_components.slice(0, fixture_components.length - 1), // Drop final divider
        });

        return group;
    }

    render() {
        return (
            <div className="scorecard-group-cards">
                {
                    (this.state.fixture_elems.length === 0) ?
                        <p className="scorecard-group-empty-text">No Fixtures</p> :
                        this.state.fixture_elems
                }
            </div>
        );
    }

}