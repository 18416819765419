"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.standard_date_string = exports.standard_date = void 0;
// "2022-10-13" or "10/13/2022" -> Date()
function standard_date(d = "") {
    if (d === "")
        return new Date();
    if (d.includes("-") && !d.includes("T")) {
        d = d + "T00:00:00";
    }
    //console.log("SD", d, new Date(d))
    return new Date(d);
}
exports.standard_date = standard_date;
// Date/"2022-10-13" -> "2022-10-13"
function standard_date_string(d = new Date()) {
    let date = (d instanceof Date) ? d : standard_date(d);
    //console.log("SDS", date.getDate(), date.toLocaleDateString('en-CA', {timeZone: "America/Los_Angeles"}))
    return date.toLocaleDateString('en-CA', { timeZone: "America/Los_Angeles" });
}
exports.standard_date_string = standard_date_string;
